<template>
  <div>
    <el-row
      type="flex"
      justify="end"
    >
      <div
        @click="collapse = !collapse"
        class="mr-2"
      >
        <el-popover
          class="btn btn-micro"
          placement="top-start"
          width="100"
          trigger="hover"
          content="Filtrar Ação"
        >
          <i
            slot="reference"
            :class="collapse? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          ></i></el-popover>
      </div>
      <div @click="irParaCadastrarAcao">
        <el-popover
          class="btn btn-micro"
          placement="top-start"
          width="100"
          trigger="hover"
          content="Cadastrar Ação"
        >
          <i
            slot="reference"
            class="glyphicon glyphicon-plus-sign"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <br>
    <el-collapse-transition>
      <vuestic-widget v-show="collapse">
        <div class="acoes">
          <div class="filtro input-group">
            <div class="todos">
              <fieldset>
                <vuestic-radio-button
                  option=""
                  value
                  v-model="situacaoFiltrada"
                  label="Todos"
                />
              </fieldset>
            </div>
            <div class="atrasado">
              <fieldset>
                <vuestic-radio-button
                  option="2"
                  value
                  v-model="situacaoFiltrada"
                  label="Atrasado"
                />
              </fieldset>
            </div>
            <div class="concluido">
              <fieldset>
                <vuestic-radio-button
                  option="3"
                  value
                  v-model="situacaoFiltrada"
                  label="Concluido"
                />
              </fieldset>
            </div>
            <div class="no-prazo">
              <fieldset>
                <vuestic-radio-button
                  option="1"
                  value
                  v-model="situacaoFiltrada"
                  label="No prazo"
                />
              </fieldset>
            </div>
            <div class="cancelado">
              <fieldset>
                <vuestic-radio-button
                  option="4"
                  value
                  v-model="situacaoFiltrada"
                  label="Cancelado"
                />
              </fieldset>
            </div>
            <div class="minhas">
              <fieldset>
                <vuestic-checkbox
                  label="Minhas"
                  v-model="minhasAcoes"
                />
              </fieldset>
            </div>
          </div>
        </div>
        <form action="">
          <fieldset>
            <div class="va-row">
              <div class="flex md2 xs6">
                <div class="form-group">
                  <input
                    id="ferrament"
                    v-model="advancedSearch.ferramenta"
                    required
                  >
                  <label
                    class="control-label"
                    for="ferrament"
                  > Ferramenta</label>
                </div>
              </div>

              <div class="flex md2 xs6">
                <div class="form-group">
                  <input
                    id="Assunto"
                    v-model="advancedSearch.assunto"
                    required
                  >
                  <label
                    class="control-label"
                    for="Assunto"
                  > Assunto</label>
                </div>
              </div>

              <div class="flex md2 xs6">
                <div class="form-group">
                  <input
                    id="oq"
                    v-model="advancedSearch.oQue"
                    required
                  >
                  <label
                    class="control-label"
                    for="oq"
                  > O que ?</label>
                </div>
              </div>
              <div class="flex md2 xs6">
                <div class="form-group">
                  <input
                    id="responsavel"
                    v-model="advancedSearch.responsavel"
                    required
                  >
                  <label
                    class="control-label"
                    for="responsavel"
                  > Responsável</label>
                </div>
              </div>
              <div class="flex md2 xs6">
                <div class="form-group">
                  <input
                    id="area"
                    v-model="advancedSearch.area"
                    required
                  >
                  <label
                    for="area"
                    class="control-label"
                  >Área</label>
                </div>
              </div>
              <div class="flex md2 xs6">
                <div class="form-group">
									<!--
                  <vuestic-date-picker
                    id="date-form-range"
                    :config="{
                    mode: 'range',
                    dateFormat: 'd/m/Y',
                    }"
                    v-model="data"
                  />
                  <i
                    v-if="this.data"
                    class="fa fa-times icon-right input-icon pointer"
                    @click="clear()"
                  >
                  </i>
                  <label
                    class="control-label"
                    for="date-form-range"
                  >
                    Data de cadastro
                  </label>
									-->
									<el-date-picker
              format="dd/MM/yyyy"
              v-model="data"
              type="daterange"
              range-separator="|"
              start-placeholder="Data inicial"
              end-placeholder="Data final"
              value-format="dd/MM/yyyy"
            >
            </el-date-picker>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <form>
          <el-row
            type="flex"
            class="row-bg"
            justify="end"
          >
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="pesquisaAcao()"
            >
              Filtrar
            </el-button>

            <el-button
              class="staly-export-button"
              type="info"
              icon="el-icon-download"
              @click="exportar"
            >
              Exportar
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-close"
              @click="limparPesquisa"
            >
              Limpar
            </el-button>

          </el-row>
        </form>
      </vuestic-widget>
    </el-collapse-transition>
    <vuestic-widget>
      <div class="fex md12">
        <div>
          <data-tables-server
            :pagination-props="{ pageSizes: [10, 20, 50, 100] }"
            :total="count"
            @query-change="pesquisaAcao"
            style="width: 100%;"
            :data="acoes"
            @row-dblclick="editar"
            :table-props="tableProps"
            v-loading="loading"
          >
            <el-table-column
              prop="situacao"
              label="Situação"
              sortable="custom"
              width="130"
            >
              <template
                slot-scope="row"
                style="width: 100%;"
              >
                <div
                  :class="cores[row.row.situacao]"
                  class="badge"
                >{{situacao[row.row.situacao]}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="origem_nome"
              label="Ferramenta"
              sortable="custom"
              width="130"
            />
            <el-table-column
              prop="assunto"
              label="Assunto"
              sortable="custom"
              width="130"
            />
            <el-table-column
              prop="o_que"
              label="O que"
              sortable="custom"
              width="280"
            />
            <el-table-column
              prop="como"
              label="Como"
              width="320"
            />
            <el-table-column
              prop="responsavel_nome"
              label="Responsável"
              sortable="custom"
              width="220"
            />
            <el-table-column
              prop="responsavel_cadastro_nome"
              label="Solicitante"
              sortable="custom"
              width="220"
            />
            <el-table-column
              prop="area_nome"
              label="Área"
              sortable="custom"
              width="130"
            />
            <el-table-column
              prop="prazo"
              label="Prazo"
              width="130"
            >
              <template slot-scope="scope">
                <div>
                  {{dataFormatBr(scope.row.prazo)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="data_cadastro"
              label="Cadastro"
              width="130"
            >
              <template slot-scope="scope">
                <div>
                  {{dataFormatBr(scope.row.data_cadastro)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="data_finalização"
              label="Finalização"
              width="130"
            >
              <template slot-scope="scope">
                <div>
                  {{dataFormatBr(scope.row.data_finalização)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="comentario_finalização"
              label="Comentario"
              width="280"
            />
          </data-tables-server>
        </div>
      </div>
    </vuestic-widget>
    <modal-edicao-acao-vue
      ref="modalEdit"
      v-on:success="atualizarAcao"
      :ferramentas="ferramentas"
      :acaoEscolhida="acaoSelecionada"
      :areas="areas"
      :responsaveis="responsaveis"
      :indicadores="indicadores"
    />
  </div>
</template>

<script>
import modalEdicaoAcaoVue from '../form/editar/modal/acao.vue';

/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
export default {
  name: 'lista-acoes',
  components: {
    modalEdicaoAcaoVue,
  },
  data() {
    return {
      data: [],
      url: '',
      loading: false,
      collapse: false,
      tableProps: {
        stripe: true,
      },
      count: 0,
      acao: '',
      advancedSearch: {
        ferramenta: '',
        oQue: '',
        assunto: '',
        responsavel: '',
        area: '',
      },
      situacaoFiltrada: '',
      minhasAcoes: false,
      areas: [],
      ferramentas: [],
      responsaveis: [],
      empresaFull: [],
      indicadores: [],
      // eslint-disable-next-line radix
      empresa: parseInt(localStorage.empresaSelecionada),
      acoes: [],
      acaoSelecionada: {},
      situacao: ['', 'No prazo', 'Atrasado', 'Concluído', 'Cancelado'],
      cores: ['', 'badge-info', 'badge-danger', 'badge-success', 'badge-dark'],
      dataFormat: null,
    };
  },
  created() {
    this.getAcao();
    // eslint-disable-next-line global-require
    this.dataFormat = require('moment');
    this.getEmpresa();
  },
  methods: {
    async getAcao() {
      try {
        this.loading = true;
        const restult = await this.$axios.get(`/api2/acao-por-empresa/${this.empresa}/`);
        this.acoes = restult.data.results;
        this.count = restult.data.count;
        this.loading = false;
      } catch (erro) {
        this.loading = false;
      }
    },
    limparPesquisa() {
      this.getAcao();
      this.advancedSearch = {
        ferramenta: '',
        oQue: '',
        assunto: '',
        responsavel: '',
        area: '',
      };
      this.data = [];
      this.situacaoFiltrada = '';
    },
    exportar() {
      this.$swal({
        title: 'Preparando o Arquivo',
      });
      this.$swal.showLoading();

      this.$axios({
        method: 'GET',
        url: this.url,
        responseType: 'blob',
      }).then((res) => {
        this.$swal.close();

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Acões.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch(() => {
        this.$swal.close();
      });
    },
    endPointPesquisa(paginaAtual) {
      let pagina = '';
      let minhas = '';
      let data = '';
      let situacao = '';
      let ferramenta = '';
      let oQue = '';
      let assunto = '';
      let responsavel = '';
      let area = '';
      let paginaSize = '';
      const orderBy = paginaAtual ? this.sort(paginaAtual.sort) : '';
      const { advancedSearch } = this;
      if (paginaAtual) {
        pagina = `page=${paginaAtual.page}&`;
      }
      if (paginaAtual) {
        paginaSize = `paginas=${paginaAtual.pageSize}&`;
      }
      if (this.minhasAcoes) {
        minhas = 'minhas';
      }
      if (this.data && this.data.length === 2) {
        const dateMin = this.data[0].replace(/\//g, '%2F');
        const dateMax = this.data[1].replace(/\//g, '%2F');
        data = `tipo=cadastro&min_date=${dateMin}&max_date=${dateMax}&`;
      }
      if (this.situacaoFiltrada) {
        situacao = `situacao=${this.situacaoFiltrada}&`;
      }
      if (advancedSearch.oQue) {
        oQue = `o_que=${advancedSearch.oQue}&`;
      }
      if (advancedSearch.assunto) {
        assunto = `assunto=${advancedSearch.assunto}&`;
      }
      if (advancedSearch.responsavel) {
        responsavel = `responsavel=${advancedSearch.responsavel}&`;
      }
      if (advancedSearch.area) {
        area = `area=${advancedSearch.area}&`;
      }
      if (advancedSearch.ferramenta) {
        ferramenta = `ferramenta=${advancedSearch.ferramenta}&`;
      }
      const pesquisa = `?${paginaSize}${orderBy}${pagina}${data}${situacao}${ferramenta}${oQue}${assunto}${responsavel}${area}${minhas}`;
      const filtros = pesquisa.replace(/&+$/g, '');
      return filtros;
    },
    sort(orderBy) {
      let newOrder = '';
      if (orderBy.order === 'descending') {
        newOrder = '-';
      }
      if (orderBy.prop === 'situacao') {
        newOrder += 'situacao';
      } else if (orderBy.prop === 'origem_nome') {
        newOrder += 'origem__ferramenta';
      } else if (orderBy.prop === 'assunto') {
        newOrder += 'assunto';
      } else if (orderBy.prop === 'o_que') {
        newOrder += 'o_que';
      } else if (orderBy.prop === 'responsavel_nome') {
        newOrder += 'responsavel__nome';
      } else if (orderBy.prop === 'responsavel_cadastro_nome') {
        newOrder += 'responsavel_cadastro__nome';
      } else if (orderBy.prop === 'area_nome') {
        newOrder += 'area__nome';
      }
      return `ordering=${newOrder}&`;
    },
    async pesquisaAcao(paginaAtual) {
      this.$axios.get(`/api2/acao-por-empresa/${this.empresa}/${this.endPointPesquisa(paginaAtual)}`)
        .then((res) => {
          this.acoes = res.data.results;
          this.count = res.data.count;
          this.url = `${this.$axios.defaults.baseURL}/exportar-csv/${this.endPointPesquisa(paginaAtual)}&empresa=${this.empresaFull.nome}`;
        });
    },
    dataFormatBr(data) {
      return data ? this.dataFormat(data).format('DD/MM/YYYY') : '';
    },
    atualizarAcao() {
      this.pesquisaAcao();
    },
    editar(row) {
      this.acaoSelecionada = row;
      this.$refs.modalEdit.open();
    },
    getEmpresa() {
      this.$axios.get(`/api/empresa-list/${this.empresa}/`).then((res) => {
        this.empresaFull = res.data;
        this.getAreas();
        this.getIndicadores();
        this.getResponsaveis();
        this.getFerramentas();
      });
    },


    // Chamadas para edicao
    getAreas() {
      this.$axios.get('/api/area-list/').then((res) => {
        this.areas = (res.data).filter(area => this.empresaFull.areas.indexOf(area.id) != -1);
      });
    },
    getIndicadores() {
      this.$axios.get('/api/indicador-list/').then((res) => {
        this.indicadores = (res.data)
          .filter(indicador => this.empresaFull.indicador.indexOf(indicador.id) != -1);
      });
    },
    getResponsaveis() {
      this.$axios.get('/api/funcionario-list/').then((res) => {
        const filtrarResponsavePorEmpresaFull = (res.data)
          .filter(funcionario => funcionario.empresas.indexOf(this.empresa) != -1);
        // eslint-disable-next-line
        this.responsaveis = filtrarResponsavePorEmpresaFull.sort((obj1, obj2) => ((obj1.nome).toUpperCase() < (obj2.nome).toUpperCase() ? -1 : (obj1.nome).toUpperCase() > (obj2.nome).toUpperCase() ? 1 : 0));
      });
    },
    getFerramentas() {
      this.$axios.get('/api/ferramenta-list/').then((res) => {
        this.ferramentas = (res.data).filter(f => this.empresaFull.ferramenta.indexOf(f.id) !== -1);
      });
    },
    irParaCadastrarAcao() {
      const nameUrlRoot = this.$router.history.current.matched[0].name;
      if (nameUrlRoot === 'User') {
        this.$router.push({ name: 'cadastrar-acao' });
      } else {
        this.$router.push({ name: 'cadastrar-acao-supervisor' });
      }
    },
  },
  watch: {
    situacaoFiltrada() {
      this.pesquisaAcao();
    },
    minhasAcoes() {
      this.pesquisaAcao();
    },
    advancedSearch: {
      deep: true,
      handler() {
        this.url = `${this.$axios.defaults.baseURL}/exportar-csv/${this.endPointPesquisa()}&empresa=${this.empresaFull.nome}`;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$break-small: 520px;
$break-large: 1200px;
$break-clr: 458px;
$breakpoints: (
	'small': 576px,
	'medium': 768px,
	'large': 992px,
	'xlarge': 1200px
) !default;

@mixin respond-to($breakpoint) {
	// If the key exists in the map
	@if map-has-key($breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media (max-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	}

	// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
+ "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

$list-filtros: 'todos' 'atrasado' 'concluido' 'no-prazo' 'cancelado' 'minhas';
.acoes {
	display: flex;
	align-items: center;
	// grid-template-areas: "filtro botoes";
	justify-content: space-between;
	margin-bottom: 10px;
	@include respond-to('medium') {
		flex-wrap: wrap;
	}
	.filtro {
		display: grid;
		align-items: center;
		grid-template-areas: 'todos atrasado concluido no-prazo cancelado minhas';

		@include respond-to('medium') {
			grid-template-areas: 'todos atrasado concluido' 'no-prazo cancelado minhas';
		}

		@include respond-to('small') {
			grid-template-columns: 3fr 2fr;
			grid-template-areas:
				'todos'
				'atrasado'
				'concluido'
				'no-prazo'
				'cancelado'
				'minhas';
		}

		@each $item in $list-filtros {
			$selector: unquote('.#{$item}');
			#{$selector} {
				grid-area: $item;
				margin-right: 1px;
				margin-left: 10px;
			}
		}
	}
	.acoes--botoes {
		@include respond-to('medium') {
			margin: 20px 0 30px 0;
		}
		@include respond-to('small') {
			margin: 20px 0 10px 0;
		}
		* {
			margin-right: 10px;
			@include respond-to('small') {
				width: 100%;
				margin: 0 0 15px 0;
			}
		}
	}
}
.butoess {
	display: flex;
}
.no-margin {
	margin-top: none;
	margin: none;
	margin-bottom: none;
}
input[type='radio'] {
	margin-right: 0%;
}
input[type='checkbox'] {
	margin-right: 0%;
}
hr {
	margin: 0% !important;
}
.acoes {
	@media screen and (max-width: $break-small) {
		.butoess {
			flex-basis: 100%;
		}
	}
	@media screen and (max-width: $break-clr) {
		.butoess {
			display: flex;
			justify-content: space-around;
		}
	}
}
.form-group .flatpickr-input {
	background: none;
}
.tirahr {
	display: none;
}
@media screen and (max-width: $break-clr) {
	.tirahr {
		display: block;
	}
}
.efeito {
	transition: 500ms;
}
</style>
<style>
.el-table th {
	min-width: 120px;
}
.el-table .cell {
	word-break: normal !important;
}
.el-table_1_column_1 .cell {
	padding-left: 0px;
}
.badge {
	padding: 0.28rem 0.3em !important;
}
.fa-times {
	margin-left: 90%;
}
.staly-export-button a {
	color: white;
}
</style>

<style>
.teste {
	float: right !important;
}
</style>
